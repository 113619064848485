<!-- 报表 -->
<template>
  <div id="ReportForms">
    <van-nav-bar
      :title="$t('m_header.ykbb')"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    >
      <template #right>
        <i style="color: #000;font-size: 0.4rem;" class="iconfont icon-shaixuanguolv"></i>
      </template>
    </van-nav-bar>
		<div class="wrap">
      <div class="report_money">
        <h3>{{ $t("m_profitandLossStatement.ykje") }}</h3>
        <!-- <p class="money">{{ touziyingkui | privatefg}}</p> -->
       <p class="money">{{ touziyingkui | privateMoney }}</p>
        <p>
          {{ $t("m_profitandLossStatement.ylgs") }}：
          <span>{{ $t("m_profitandLossStatement.hdgs") }}</span>
          <span>+{{ $t("m_profitandLossStatement.tzfl") }}</span>
          <span>+{{ $t("m_profitandLossStatement.ljyj") }}</span>
          <span>+{{ $t("m_profitandLossStatement.zkzj") }}</span>
          <span>+{{ $t("m_profitandLossStatement.jszj") }}</span>
          <span>+{{ $t("m_profitandLossStatement.hdzj") }}</span>
          <span>-{{ $t("m_profitandLossStatement.tzmr") }}</span>
        </p>
      </div>

      <ul class="order-list">
        <li>
          <span>{{ touziyingkui }}</span
          ><i>{{ $t("m_profitandLossStatement.tzyk") }}</i>
        </li>
        <li>
          <span>{{ shouyi }}</span
          ><i>{{ $t("m_profitandLossStatement.hdgs") }}</i>
        </li>
        <li>
          <span>{{ touzimairu }}</span
          ><i>{{ $t("m_profitandLossStatement.tzmr") }}</i>
        </li>
        <li>
          <span>{{ touzifanli }}</span
          ><i>{{ $t("m_profitandLossStatement.tzfl") }}</i>
        </li>
        <li>
          <span>{{ leijiyongjin }}</span
          ><i>{{ $t("m_profitandLossStatement.ljyj") }}</i>
        </li>
        <li>
          <span>{{ zhuankazijin }}</span
          ><i>{{ $t("m_myRank.zkzj") }}</i>
        </li>
        <li>
          <span>{{ jinshengzijin }}</span
          ><i>{{ $t("m_profitandLossStatement.jszj") }}</i>
        </li>
        <li>
          <span>{{ huodongzijing }}</span
          ><i>{{ $t("m_profitandLossStatement.hdzj") }}</i>
        </li>
        <li>
          <span>{{ tixianjine }}</span
          ><i>{{ $t("m_profitandLossStatement.txje") }}</i>
        </li>
        <li>
          <span>{{ chongzhijine }}</span
          ><i>{{ $t("m_profitandLossStatement.zcje") }}</i>
        </li>
      </ul>
    </div>
    
		<!-- 抽屉 -->
    <van-popup v-model="show" position="right" :style="{ height: '100%' }">
      <div class="subNav" style="width: 4.9rem">
        <h3 class="title">{{$t("m_profitandLossStatement.sj")}}</h3>
        <van-popover
          v-model="showPopover"
          trigger="click"
          :actions="actions"
          @select="onSelect"
        >
          <template #reference>
            <div class="select">
              <div class="default">{{ defultTxt }}</div>
            </div>
          </template>
        </van-popover>
        <div class="date">
          <div class="dateItem startDate" @click="showPickerFun('start')">
            {{ start }}
          </div>
          <span></span>
          <div class="dateItem endtDate" @click="showPickerFun('end')">
            {{ end }}
          </div>
        </div>
        <footer class="btns">
          <button @click="resetDate">{{$t("m_profitandLossStatement.cz")}}</button>
          <button @click="poputCfrim">{{$t("m_profitandLossStatement.qr")}}</button>
        </footer>
      </div>
    </van-popup>
    <!-- 时间选择器 -->
    <van-popup v-model="showPicker" position="bottom">
      <van-datetime-picker
        type="date"
        :confirm-button-text="$t('m_profitandLossStatement.qd')"
        :cancel-button-text="$t('m_profitandLossStatement.qx')"
        @confirm="onConfirm"
        @cancel="showPicker = false"
        v-model="currentDate"
      />
    </van-popup>
  </div>
</template>

<script>
import { report } from "@/api/user";
import { Toast } from "vant";
export default {
  name: "",
  data() {
    return {
      defultTxt: this.$t('m_profitandLossStatement.jr'),
      showPopover: false,
      currentDate: "",
      timeType: "start",
      showPicker: false,
      start: "", // 开始时间
      end: "", // 结束时间
      chongzhijine: 0,
      huodongzijing: 0,
      jinshengzijin: 0,
      leijiyongjin: 0,
      shouyi: 0,
      tixianjine: 0,
      touzifanli: 0,
      touzimairu: 0,
      touziyingkui: 0,
      zhuankazijin: 0,
      show: false,
      actions: [
        { text: this.$t('m_profitandLossStatement.jr'), value: "1" },
        { text: this.$t('m_profitandLossStatement.zr'), value: "2" },
        { text: this.$t('m_profitandLossStatement.bz'), value: "3" },
        { text: this.$t('m_profitandLossStatement.sz'), value: "4" },
        { text: this.$t('m_profitandLossStatement.by'), value: "5" },
        { text: this.$t('m_profitandLossStatement.sy'), value: "6" },
      ],
      lastmonth_end: "", // 上月
      lastmonth_start: "", // 上月
      lastweek_end: "", // 上周
      lastweek_start: "", // 上周
      month_end: "", // 本月
      month_start: "", // 本月
      today_end: "", // 今日
      today_start: "", // 今日
      week_end: "", // 本周
      week_start: "", // 本周
      yesterday_end: "", // 昨日
      yesterday_start: "", // 昨日
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    this.start = this.getNowTime(new Date(), "dd-MM-yyyy");
    this.end = this.getNowTime(new Date(), "dd-MM-yyyy");
    this.reportShow();
  },
  methods: {
    onClickRight() {
      this.show = true;
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    /* 选择年月日 */
    onSelect(e) {
      console.log(e.value);
      this.defultTxt = e.text;
      switch (e.value) {
        case '1':
          this.start = this.today_start;
          this.end = this.today_end;
          break;
        case '2':
          this.start = this.yesterday_start;
          this.end = this.yesterday_end;
          break;
        case '3':
          this.start = this.week_start;
          this.end = this.week_end;
          break;
        case '4':
          this.start = this.lastweek_start;
          this.end = lastweek_end;
          break;
        case '5':
          this.start = this.month_start;
          this.end = this.month_end;
          break;
        case '6':
          this.start = this.lastmonth_start;
          this.end = this.lastmonth_end;
          break;
        default:
          this.start = this.today_start;
          this.end = this.today_end;
          break;
      }
      console.log("选择了年月日");
    },
    loadingCom() {
      // 自定义加载图标
      const toast = Toast.loading({
        message: this.$t("common.loading"),
        forbidClick: true,
        loadingType: "spinner",
        duration: 60000,
      });
    },
    /**
     * 获取当前时间
     */
    getNowTime(dateVal, fmt) {
      var o = {
        "M+": dateVal.getMonth() + 1, //月份
        "d+": dateVal.getDate(), //日
        "h+": dateVal.getHours(), //小时
        "m+": dateVal.getMinutes(), //分
        "s+": dateVal.getSeconds(), //秒
        "q+": Math.floor((dateVal.getMonth() + 3) / 3), //季度
        S: dateVal.getMilliseconds(), //毫秒
      };
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (dateVal.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
        }
      }
      return fmt;
    },
    /* 打开日期选择 */
    showPickerFun(e) {
      this.timeType = e;
      if (e == "start") {
        this.currentDate = new Date(this.start);
      } else {
        this.currentDate = new Date(this.end);
      }
      this.showPicker = true;
    },
    /* 时间选择了 */
    onConfirm(e) {
      this.showPicker = false;
      if (this.timeType == "start") {
        this.start = this.getNowTime(new Date(e), "dd-MM-yyyy");
      } else {
        this.end = this.getNowTime(new Date(e), "dd-MM-yyyy");
      }
    },
    /* 点击了确认 */
    poputCfrim() {
      this.show = false;
      this.reportShow();
    },
    /* 重置 */
    resetDate() {
      this.start = this.getNowTime(new Date(), "dd-MM-yyyy");
      this.end = this.getNowTime(new Date(), "dd-MM-yyyy");
      this.reportShow();
    },
    /* 查询报表 */
    reportShow() {
      this.loadingCom();
      report({
        start: this.start,
        end: this.end,
      }).then((res) => {
        Toast.clear();
        let result = res.data;
        console.log(res);
        this.chongzhijine = result.chongzhijine;
        this.huodongzijing = result.huodongzijing;
        this.jinshengzijin = result.jinshengzijin;
        this.leijiyongjin = result.leijiyongjin;
        this.shouyi = result.shouyi;
        this.tixianjine = result.tixianjine;
        this.touzifanli = result.touzifanli;
        this.touzimairu = result.touzimairu;
        this.touziyingkui = result.touziyingkui;
        this.zhuankazijin = result.zhuankazijin;

        this.lastmonth_end = result.lastmonth_end; // 上月
        this.lastmonth_start = result.lastmonth_start; // 上月
        this.lastweek_end = result.lastweek_end; // 上周
        this.lastweek_start = result.lastweek_start; // 上周
        this.month_end = result.month_end; // 本月
        this.month_start = result.month_start; // 本月
        this.today_end = result.today_end; // 今日
        this.today_start = result.today_start; // 今日
        this.week_end = result.week_end; // 本周
        this.week_start = result.week_start; // 本周
        this.yesterday_end = result.yesterday_end; // 昨日
        this.yesterday_start = result.yesterday_start; // 昨日
      });
    },
  },
};
</script>
<style scoped lang="less">
@import "../../../assets/css/mobile.less";

.van-nav-bar {
  background-image: linear-gradient(0deg, #ceab77 10%, #eddbc1 100%) !important;
}

.van-picker {
  background-color: #fff;
}

/deep/.van-nav-bar__title {
  color: #38373b !important;
}
/deep/.van-nav-bar .van-icon {
  color: #38373b !important;
}

.wrap {
  padding: 0.2rem 0.3rem;
  width: 100%;
  box-sizing: border-box;

  .report_money {
    width: 100%;
    padding: 0.2rem 0.5rem;
    border-radius: 0.2rem;
    box-sizing: border-box;
    background-image: linear-gradient(0deg, #ceab77 0%, #eddbc1 100%);

    h3 {
      font-size: 0.34rem;
      color: #000;
      line-height: 0.5rem;
      text-align: center;
    }

    p {
      color: #000;
      font-size: 0.24rem;
      text-align: center;
      line-height: 0.4rem;
    }

    p.money {
      color: #b2812b;
      font-size: 0.6rem;
      line-height: 0.8rem;
    }
  }

  .order-list {
    margin-top: 0.2rem;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;

    li {
      box-sizing: border-box;
      width: 2.2rem;
      height: 1.8rem;
      background-color: #524f45;
      border-radius: 0.2rem;
      margin-right: 0.1rem;
      margin-bottom: 0.1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      span {
        width: 100%;
        text-align: center;
        font-size: 0.36rem;
        color: #b2812b;
        line-height: 0.6rem;
      }

      i {
        width: 100%;
        text-align: center;
        font-size: 0.24rem;
        color: #ffffff;
      }
    }
  }
}

.van-popup {
  background-color: #524f45;

  .subNav {
    padding: 0.3rem;
    box-sizing: border-box;
    width: 100%;
    min-height: 100%;
    background-color: #524f45;
    position: relative;

    .title {
      color: #ffffff;
      font-size: 0.3rem;
      line-height: 0.4rem;
      margin-bottom: 0.3rem;
      font-family: PingFang-SC-Medium;
    }

    .select {
      position: relative;

      .default {
        width: 1.86rem;
        height: 0.56rem;
        background-color: #484438;
        border-radius: 0.1rem;
        border: solid 1px #786f52;
        color: #b4b4b4;
        line-height: 0.56rem;
        text-align: center;
        font-size: 0.24rem;

        ul {
          position: absolute;
          left: 0.02rem;
          top: 0.54rem;
          width: 1.82rem;
          background-color: #f5f5f5;
          z-index: 10;
          border-radius: 0.05rem;

          li {
            height: 0.44rem;
            line-height: 0.44rem;
            text-align: center;
            color: #4e4c4c;
            font-size: 0.24rem;

            &.active {
              background-image: linear-gradient(0deg, #ceab77 0%, #eddbc1 100%);
              color: #38373b;
            }
          }
        }
      }
    }

    .date {
      display: flex;
      justify-content: space-between;
      position: relative;
      margin-top: 0.3rem;

      .startDate {
        margin-right: 0.4rem;
      }

      span {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        height: 1px;
        background-color: #c4c4c4;
        width: 0.2rem;
      }

      .dateItem {
        width: 1.86rem;
        height: 0.56rem;
        background-color: #484438;
        border-radius: 0.1rem;
        border: solid 1px #786f52;
        color: #b4b4b4;
        line-height: 0.56rem;
        text-align: center;
        font-size: 0.24rem;
      }
    }

    .btns {
      position: absolute;
      bottom: 0rem;
      left: 0;
      width: 100%;
      height: 0.88rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 1px solid #786f52;
      box-sizing: border-box;

      button {
        width: 50%;
        height: 100%;
        font-size: 0.34rem;
        color: #ffffff;
        background-color: transparent;
        border: none;
      }

      button:last-child {
        background-image: linear-gradient(0deg, #ceab77 0%, #eddbc1 100%);
        color: #38373b;
      }
    }
  }
}
</style>